<template>
  <div id="briefScan" class="Content-Page">
    <div class="B-briefFilght" v-if="!notFlight">
      <div class="box-S4 flex-between-start">
        <!-- <div class="B-logo">
          <div class="b-logo"></div>
        </div> -->
        <div class="box-S3">
          <!-- Flight No. -->
          <div id="flightDetail" class="box-S4 noPadding flex-between">
            <div class="box-S2 noPadding">
              <div class="N-Page T-size-36">Flight No. : {{ flightFinal.f_flight_no }}</div>
              <div class="N-Page T-size-36">Departure : {{ flightFinal.departairport }}</div>
            </div>
            <div class="box-S2 noPadding">
              <div class="N-Page T-size-36">Flight Time : {{flightFinal.ap_depart_date | HHmm}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Flight Route -->
      <div id="flightRoute" class="B-carddate">
        <div class="box-S4 noPadding">
          <div class="textRoute T-size-36">
            <div class="text-center">
              <v-icon size="36px">mdi-airplane-takeoff</v-icon>
              {{flightFinal.departairport}}
            </div>

            <div v-for="l in flightleg" :key="l.Id">
              <v-icon size="36px">mdi-arrow-right-drop-circle</v-icon>
              {{ l.ap_name }}
            </div>

            <div class="text-center">
              <v-icon size="36px">mdi-airplane-landing</v-icon>
              {{flightFinal.deestairport}}
            </div>
          </div>
        </div>
      </div>
      <!-- Seat and List Name -->
      <div class="box-S4 noPadding flex-between">
        <!-- Seat -->
        <div class="box-S2">
          <div class="B-carddate">
            <div class="T-TitlePosition T-size-24 nose">NOSE</div>
            <div class="B-seatPosition">
              <div class="seatPosition">
                <img v-if="!listPassenger[3].Status" src="/img2/red_d.png" />
                <img v-if="listPassenger[3].Status" src="/img2/green_d.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[2].Status" src="/img2/red_c.png" />
                <img v-if="listPassenger[2].Status" src="/img2/green_c.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[1].Status" src="/img2/red_b.png" />
                <img v-if="listPassenger[1].Status" src="/img2/green_b.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[0].Status" src="/img2/red_a.png" />
                <img v-if="listPassenger[0].Status" src="/img2/green_a.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[7].Status" src="/img2/red_h.png" />
                <img v-if="listPassenger[7].Status" src="/img2/green_h.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[6].Status" src="/img2/red_g.png" />
                <img v-if="listPassenger[6].Status" src="/img2/green_g.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[5].Status" src="/img2/red_f.png" />
                <img v-if="listPassenger[5].Status" src="/img2/green_f.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[4].Status" src="/img2/red_e.png" />
                <img v-if="listPassenger[4].Status" src="/img2/green_e.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[11].Status" src="/img2/red_l.png" />
                <img v-if="listPassenger[11].Status" src="/img2/green_l.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[10].Status" src="/img2/red_k.png" />
                <img v-if="listPassenger[10].Status" src="/img2/green_k.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[9].Status" src="/img2/red_j.png" />
                <img v-if="listPassenger[9].Status" src="/img2/green_j.png" />
              </div>
              <div class="seatPosition">
                <img v-if="!listPassenger[8].Status" src="/img2/red_i.png" />
                <img v-if="listPassenger[8].Status" src="/img2/green_i.png" />
              </div>
            </div>
            <div class="T-TitlePosition T-size-24 tail">Tail</div>
          </div>
        </div>
        <!-- List Name   -->
        <div class="box-S2">
          <div class="B-carddate">
            <div class="listPassenger" v-for="p in passengersFinal" :key="p.Id">
              <div
                class="Passenger"
                v-if="!p.p_brief_checkin"
                id="passenger"
                style="opacity: 0.3;"
              >
                <div>
                  <div class="seatNum">{{ p.p_seat_no}}</div>
                  <div class="picDisplay" :style="{ 'background-image': p.p_imageurl }"></div>
                  <div
                    class="namePassenger"
                  >{{ p.u_firstname.trim() }}.{{ p.u_lastname.trim().substring(0,1) }}</div>
                </div>
                <div>
                  <div class="aiport" :style="{ 'background-color': p.ap_destairport_color }">
                    <v-icon style="color:white;" class="display-1">mdi-airplane-landing</v-icon>
                    {{p.ap_destairport_name}}
                  </div>
                </div>
              </div>
              <div class="Passenger" v-if="p.p_brief_checkin" id="passenger">
                <div>
                  <div class="seatNum">{{ p.p_seat_no}}</div>
                  <div class="picDisplay" :style="{ 'background-image': p.p_imageurl }"></div>
                  <div
                    class="namePassenger"
                  >{{ p.u_firstname.trim() }}.{{ p.u_lastname.trim().substring(0,1) }}</div>
                </div>
                <div>
                  <div class="aiport" :style="{ 'background-color': p.ap_destairport_color }">
                    <v-icon style="color:white;" class="display-1">mdi-airplane-landing</v-icon>
                    {{p.ap_destairport_name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="B-noFlight" v-if="notFlight">
      <div class="B-logo">
        <div class="b-logo"></div>
      </div>
      <div>No Flight</div>
    </div>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  props: ["Id"],
  data: () => ({
    //--start config
    interval: null,
    title: "",

    notFlight: false,
    flight: [],
    flightFinal: [],
    flightleg: [],
    passengers: [],
    passengersFinal: [],
    listPassenger: [
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      },
      {
        Status : false
      }
    ],

    service: "flight",
    objectName: "Flight",
    headers: [
      { value: "", text: "", sortable: true },
      { value: "Name", text: "Name", sortable: true },
      { value: "Company", text: "Company", sortable: true },
      { value: "Destination", text: "Destination", sortable: true },
      { value: "CheckIn", text: "CheckIn", sortable: true },
      { value: "Weight", text: "Weight", sortable: true },
      { value: "AlcoholStatus", text: "Alcohol", sortable: true },
      { value: "Detail", text: "Detail", sortable: true },
      { value: "Delete", text: "Delete", sortable: true },
      { text: "", sortable: false }
    ],
    dateNow: new Date(),

    snackbar: false,
    y: "botton",
    x: null,
    mode: "",
    timeout: 10000,
    text: ""
  }),
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    }
  },
  async mounted() {
    //init here
    this.renderUI();
  },

  //   filters: {
  //     date: createDateFilter("DD/MM/YY", { locale }),
  //     dateC: createDateFilter("DD/MM/YY HH:mm:ss", { locale }),
  //     dateT: createDateFilter("HH:mm", { locale })
  //   },

  created() {
    this.interval = setInterval(() => {
      this.renderUI();
    }, 1000);
  },

  methods: {
    async renderUI() {
      try {
        const q = {};
        q.TerminalId = this.$route.params.id;

        var resdata = await feathersClientUOA
          .service("briefcheckintvcommand")
          .find({ query: q });
        var res = resdata[0];
        //alert(JSON.stringify(res))

        if (res.FlightStatus) {
          this.flightFinal = res.Flight[0];
          this.flightleg = res.Leg[0];
          this.listPassenger = res.Passengers;
          this.passengersFinal = res.PassengersFinal;
          this.notFlight = false;
          if (
            this.passengersFinal.filter(x => x.p_brief_checkin == null)
              .length == 0
          ) {
            clearInterval(this.interval); // stop the interval
            let i = 10;

            this.snackbar = true;
            let intervalSnacbar = setInterval(() => {
              i = i - 1;
              this.text = "VDO start in " + i + " second";

              if (i == -1) {
                clearInterval(intervalSnacbar);
                this.UpdateFlightStatusTackOff(this.flightFinal.f_id);
              }
            }, 1000);
          }
        } else {
          this.notFlight = true;
        }

        // var res = await this.$store.dispatch(this.service + "/find", {
        //   query: {
        //     TerminalId: this.Id,
        //     StatusId: 4,
        //     $eager: "[passenger, leg, departairport]"
        //   }
        // });
      } catch (error) {
        console.log(error);
      }
    },

    //Flight มากไปน้อย
    sortedArrayForFlight() {
      function compare(b, a) {
        if (a.ap_depart_date < b.ap_depart_date) return -1;
        if (a.ap_depart_date > b.ap_depart_date) return 1;
        return 0;
      }
      return this.flight.sort(compare);
    },

    //Passenger น้อยไปมากโดยเอา flight ล่าสุดมา
    sortedArrayForPassenger() {
      function compare(a, b) {
        if (a.p_seat_no < b.p_seat_no) return -1;
        if (a.p_seat_no > b.p_seat_no) return 1;
        return 0;
      }
      return this.passengers.sort(compare);
    },

    async UpdateFlightStatusTackOff(flightId) {
      try {
        let edit = {
          fs_id: 5
        };
        await feathersClientUOA.service("flight").patch(flightId, edit);
      } catch (err) {
        console.log(err);
      } finally {
        // window.location.replace(
        //   process.env.VUE_APP_VDO_URL + "?terminal=" + this.Id
        // );
        let url = process.env.VUE_APP_VDO_URL + this.$route.params.id;
        // window.location.replace(url);

        window.open(url,'_blank');
        window.close();
      }
    }
  }
};
</script> 


<style>
div#contentMain.briefscan {
  padding-top: 0px;
  width: 100%;
  margin: 0 30px;
}
#passenger li {
  list-style-type: none;
}
</style>
